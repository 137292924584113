<template>
  <div id="examineList">
    <el-breadcrumb separator="/" style="padding-left:10px;padding-bottom:10px;font-size:12px;">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>进入人工审核</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card class="box-card">
      <el-form :inline="true" ref="form" :model="queryMap" label-width="70px" size="small">

        <el-form-item label="手机号">
          <el-input clearable v-model="queryMap.phoneNumber"
                    placeholder="请输入手机号查询"></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input clearable v-model="queryMap.email"
                    placeholder="请输入邮箱查询"></el-input>
        </el-form-item>
        <el-form-item style="margin-left:50px;">
          <el-button type="warning" @click="reset" icon="el-icon-refresh">重置</el-button>
          <el-button type="primary" @click="search" icon="el-icon-search">查询</el-button>
        </el-form-item>
      </el-form>

      <!-- 表格区域 -->
      <el-table v-loading="loading" size="small" :data="examineList" border style="width: 100%;" height="600">
        <el-table-column label="No" width="50">
          <template slot-scope="scope">
            {{ scope.$index + (queryMap.pageNum - 1) * queryMap.pageSize + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="phoneNumber" label="手机号" width="120">
          <template slot-scope="scope">
            {{scope.row.loanInfo.phoneNumber}}
          </template>
        </el-table-column>
        <el-table-column prop="idNumber" label="身份证号" width="200">
          <template slot-scope="scope">
            {{scope.row.loanInfo.idNumber}}
          </template>
        </el-table-column>
        <el-table-column prop="email" label="邮箱" width="200">
          <template slot-scope="scope">
            {{scope.row.loanInfo.email}}
          </template>
        </el-table-column>
        <el-table-column prop="loanAmount" label="贷款金额(MXN)" width="150">
          <template slot-scope="scope">
            {{scope.row.loanInfo.loanAmount}}
          </template>
        </el-table-column>
        <el-table-column prop="loanDays" label="借款期限(天)" width="150">
          <template slot-scope="scope">
            {{scope.row.loanInfo.loanDays}}
          </template>
        </el-table-column>
        <el-table-column prop="applicationDate" label="申请时间" width="150">
          <template slot-scope="scope">
            {{scope.row.loanInfo.applicationDate}}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="审核状态" width="150">
          <
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status==0">待审核</el-tag>
            <el-tag type="success" v-if="scope.row.status==1">审核通过</el-tag>
            <el-tag type="danger" v-if="scope.row.status==-1">审核拒绝</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="400">
          <template slot-scope="scope">
            <el-button size="small" type="primary" icon="el-icon-edit-outline"
                       @click="approval(scope.row)" v-if="scope.row.status==0">审核
            </el-button>
            <el-button size="small" type="info" icon="el-icon-info"
                       @click="approval(scope.row)" v-else>详细
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
          style="margin-top:10px;"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryMap.pageNum"
          :page-sizes="[10, 20, 30]"
          :page-size="queryMap.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      ></el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "examine",
  components: {},
  data() {
    return {
      queryMap: {
        pageNum: 1,
        pageSize: 10,
        phoneNumber: '',
        email: ''
      },
      loading: true,
      examineList: [],
      total: 0
    }
  },
  methods: {
    /**
     * 审核跳转
     */
    approval(examine) {
      this.$router.push({
        path: "/finance/examine",
        query: {examine: JSON.stringify(examine)}
      });
    },
    /**
     * 查询
     */
    search() {
      this.queryMap.pageNum = 1;
      this.getExamineList();
    },
    /**
     * 加载审核列表
     */
    async getExamineList() {
      const {data: res} = await this.$http.post("system/loan/manualApproval", this.queryMap);
      this.total = res.data.total;
      this.examineList = res.data.list;
      this.loading = false;
    },
    /**
     * 重置
     */
    reset() {
      this.queryMap = {
        pageNum: 1,
        pageSize: 10,
      };
      this.search();
    },
    /**
     *  改变页码
     */
    handleSizeChange(newSize) {
      this.queryMap.pageSize = newSize;
      this.getExamineList();
    },

    /**
     * 翻页
     */
    handleCurrentChange(current) {
      this.queryMap.pageNum = current;
      this.getExamineList();
    }
  },
  created() {
    this.getExamineList();
  }
}
</script>

<style scoped>

</style>